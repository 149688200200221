<template>
  <validation-observer ref="tarificador" class="container-fluid">
    <b-row>
      <b-col cols="12">
        <span v-if="step === 4.1" @click="setStep(4)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
          {{ $t('back') }}
        </span>
        <b-row v-if="stepOnePrepare">
          <b-col sm="12">
            <b-card-title title-tag="h1" class="font-weight-bolder mb-1">
              {{ $t('orders.step') }} 1
            </b-card-title>
            <div style="width: 60px">
              <hr class="linea">
            </div>
            <p class="mb-2 font-medium-3 fw-600">{{ $t('orders.step_one_instructions') }}</p>
          </b-col>
          <b-col cols="12" sm="8">
            <b-row>
              <b-col lg="6">
                <div class="banner-title mb-3" :style="imgCortafuegos">
                  <span>{{ $t('orders.fire_curtains') }}</span>
                </div>
                <div v-for="(item) in cortafuegos" :id="item.id" :key="item.id" ref="row">
                  <b-form-group v-slot="{ cortinasItems }">
                    <b-form-radio v-model="object" :aria-describedby="cortinasItems" name="cortina-radios"
                      :value="item">
                      <p class="mb-0">
                        {{ item.name }}
                      </p>
                      <b-link v-if="item.url_web" target="_blank" :href="item.url_web">
                        {{ $t('orders.more') }}
                      </b-link>
                    </b-form-radio>
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="banner-title mb-3" :style="imgHumos">
                  <span>{{ $t('orders.smoke_curtains') }}</span>
                </div>
                <div v-for="(item) in humos" :id="item.id" :key="item.id" ref="row">
                  <b-form-group v-slot="{ cortinasItems }">
                    <b-form-radio v-model="object" :aria-describedby="cortinasItems" name="cortina-radios"
                      :value="item">
                      <p class="mb-0">
                        {{ item.name }}
                      </p>
                      <b-link v-if="item.url_web" target="_blank" :href="item.url_web">
                        {{ $t('orders.more') }}
                      </b-link>
                    </b-form-radio>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" sm="4" class="d-flex flex-column justify-content-between">
            <Summary v-if="nextButton" :nextStep="next" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Summary from '@/views/steps/Summary'
import {
  BRow, BContainer, BCol, BCardTitle, BFormGroup, BFormRadio, BLink, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    showBack: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BFormRadio,
    BLink,
    BCol,
    BCardTitle,
    BFormGroup,
    BRow,
    BContainer,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Summary,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cortafuegos: [],
      humos: [],
      nextButton: false,
      stepOnePrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      selectProducts: 'products/getSelectProductos',
      items: 'products/selectProducts',
      step: 'steps/getStep',
    }),
    object: {
      // getter
      get() {
        return this.$store.getters['steps/getObject']
      },
      // setter
      set(newValue) {
        this.nextButton = true;
        this.$store.commit('steps/setObject', newValue)
      },
    },
    imgHumos() {
      return 'background-image: url("' + require('@/assets/images/rater/humos.png') + '")'
    },
    imgCortafuegos() {
      return 'background-image: url("' + require('@/assets/images/rater/cortafuegos.png') + '")'
    },
  },
  methods: {
    ...mapActions({
      getSelectProducts: 'products/selectProducts',
    }),
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    chargeData() {
      if (this.selectProducts.length > 0) {
        this.selectProducts.forEach(element => {
          if (element.category) {
            if (element.category.ref === 'cortinas-cortafuegos') {
              this.cortafuegos.push(element)
            } else if (element.category.ref === 'cortinas-de-humo') {
              this.humos.push(element)
            }
          }
        })
        this.stepOnePrepare = true
      }
      if(this.object && this.object.id) {
        this.nextButton = true
      }
    },
    next() {
      if (!this.nextButton) {
        // alert('no ha seleccionado una cortina')
        return false
      }
      this.$refs.tarificador.validate().then(success => {
        if (success) {
          if (this.object.sin_tarifa) {
            this.setStep(this.step === 4.1 ? 4.9 : 9)
          } else {
            this.setStep(this.step === 4.1 ? 4.2 : 2)
          }
        }
      })
    },
  },
  async created() {
    await this.getSelectProducts({
      page: '',
      per_page: 99999,
      search: '',
      calculator: true,
      type: '',
      category: '',
    })
    await this.chargeData()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
