<template>
  <div>
    <div v-if="!showAllInfo">
      <p
        v-if="object.name"
        class="mb-25"
      >
        <strong>{{ $t('orders.curtain') }}</strong>
      </p>
      <p>{{ object.name }}</p>
    </div>
    <div v-if="showAllInfo">
      <b-row>
        <b-col sm="6">
          <b-row>
            <b-col cols="12">
              <p class="mb-25">
                <strong>{{ $t('orders.curtain') }}</strong>
              </p>
              <p>{{ object.name }}</p>
            </b-col>
            <b-col cols="12">
              <p class="mb-25">
                <strong>{{ $t('orders.units') }}</strong>
              </p>
              <p>{{ unidades }}</p>
            </b-col>
            <b-col cols="12">
              <p class="mb-25">
                <strong>{{ $t('orders.sizes') }}</strong>
              </p>
              <p class="mb-0">
                {{ $t('orders.length') }} (L1): {{ calculator.l1 }}mm
              </p>
              <p>{{ $t('orders.height') }} (H1): {{ calculator.h1 }}mm </p>
              <p class="mb-0">
                {{ $t('orders.length') }} (L2): {{ calculator.l2 }}mm
              </p>
              <p>{{ $t('orders.height') }} (H2): {{ calculator.h2 }}mm </p>
            </b-col>
            <b-col cols="12">
              <p class="mb-25">
                <strong>{{ $t('orders.drawer') }}</strong>
              </p>
              <p>{{ cajon }}</p>
            </b-col>
            <b-col cols="12">
              <p class="mb-25">
                <strong>{{ $t('orders.position') }}</strong>
              </p>
              <p>{{ position }}</p>
            </b-col>
            <b-col cols="12">
              <p class="mb-25">
                <strong>{{ $t('orders.side_guides') }}</strong>
              </p>
              <p v-if="object.ref !== 'MSB DH-60/DA-150 Doble' && object.ref !== 'MSB DH-60/DA-150 Simple'">
                {{ guias }}
              </p>
              <p v-else>
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="20"
                  class="text-danger"
                />
                {{ $t('orders.optional_guides_text') }}
              </p>
            </b-col>
            <b-col cols="12">
              <div v-if="object.ref === 'MFB EI-180'">
                <p class="mb-0">
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="20"
                    class="text-danger"
                  />
                  {{ $t('orders.ei180_clasification_text') }}
                </p>
                <p class="mb-0">
                  {{ $t('orders.supply_text') }}
                </p>
                <p>{{ $t('orders.supply_tecnitex_text') }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="6">
          <img
            v-if="cajon_type != '-'"
            :src="getImgMedidas(cajon_type)"
            alt=""
            class="img-fluid cajon"
          >
          <img
              v-else-if="object.ref == 'MSB DH-60/DA-150 Doble' || object.ref == 'MFBD E-120'"
              :src="getImgMedidas('medidas_doble.png')"
              alt=""
              class="img-fluid doble"
          >
          <img
            v-else
            :src="getImgMedidas('medidas.png')"
            alt=""
            class="img-fluid simple"
          >
        </b-col>
      </b-row>
    </div>
    <div class="text-right">
      <b-button
        variant="primary"
        class="mt-2 mr-1 text-uppercase"
        @click="nextStep()"
      >
        {{ $t('next') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    nextStep: Function,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      object: 'steps/getObject',
      step: 'steps/getStep',
      calculator: 'steps/getCalculator',
      medidas: 'steps/getMedidas',
      unidades: 'steps/getUnidades',
    }),
    longitud() {
      if (this.calculator) {
        if (this.medidas == 1) {
          return this.calculator.l1
        }
        return this.calculator.l2
      }
      return '-'
    },
    altura() {
      if (this.calculator) {
        if (this.medidas == 1) {
          return this.calculator.h1
        }
        return this.calculator.h2
      }
      return '-'
    },
    cajon() {
      if (this.calculator) {
        return this.calculator.cajon
      }
      return '-'
    },
    position() {
      if (this.calculator) {
        return this.calculator.position
      }
      return '-'
    },
    cajon_type() {
      if (this.calculator) {
        if(this.object.ref == 'MSB DH-60/DA-150 Doble' || this.object.ref == 'MFBD E-120'){
          if(this.calculator.cajon == 'SERIE-H-STD' || this.calculator.cajon == 'SERIE-H-XL'){
            return "medidas_doble.png"
          }else{
            return "medidas_doble_v_vf.png"
          }
        }
      }
      return '-'
    },
    guias() {
      if (this.calculator) {
        return this.calculator.guias
      }
      return '-'
    },
    showAllInfo () {
      if ([1,2,4.1,4.2].includes(this.step)) {
        return false
      }
      if ([3, 4.3].includes(this.step)) {
        return true
      }
    }
  },
  methods: {
    getImgMedidas(name) {
      return require(`@/assets/images/rater/${name}`)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
