<template>
  <b-col sm="12">
    <b-row>
      <b-col sm="12">
        <span @click="setStep(step === 4.3 ? 4.2 : 2)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
          {{ $t('back') }}
        </span>
        <b-card-title title-tag="h1" class="font-weight-bolder mb-1">
          {{ $t('orders.step') }} 1.3
        </b-card-title>
        <div style="width: 10%">
          <hr class="linea">
        </div>
        <p class="mb-2 font-medium-3 fw-600">{{ $t('orders.step_third_instructions') }}</p>
      </b-col>
      <b-col cols="12">
        <Summary :nextStep="next" />
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import Summary from '@/views/steps/Summary'
import {
  BRow, BCol, BCardTitle, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCardTitle,
    BRow,
    BCol,
    BButton,
    Summary,
  },
  computed: {
    ...mapGetters({
      calculator: 'steps/getCalculator',
      unidades: 'steps/getUnidades',
      object: 'steps/getObject',
      order: 'steps/getOrder',
      step: 'steps/getStep',
    }),
  },
  methods: {
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    ...mapActions({
      addLine: 'steps/addLine',
      checkOrderOrCreate: 'steps/checkOrderOrCreate',
    }),
    async next() {
      await this.checkOrderOrCreate()
      const attr = this.calculator
      attr.tipo = 'cortinas'
      await this.addLine({
        amount: this.calculator.precio,
        quantity: this.unidades,
        product_id: this.object.id,
        order_id: this.order.id,
        atributos: JSON.stringify(attr),
      })
      await this.setStep(4)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
