<template>
  <div>
    <div v-if="!this.facilityMode">
      <b-form-group>
        <b-form-radio
          v-model="shippingCheck"
          name="shipping-radios"
          value="tecnitex"
        >
          <strong>{{ $t("orders.pickup_tecnitex") }}</strong>
        </b-form-radio>
      </b-form-group>
    </div>
    <div v-for="item in displayAdresses" :id="item.id" :key="item.id" ref="row">
      <b-form-group>
        <b-form-radio
          v-model="shippingCheck"
          name="shipping-radios"
          :value="item.id"
        >
          <div>
            <strong>{{ item.name }}</strong>
          </div>
          <div>
            {{ item.address }}, {{ item.town }},
            {{ item.province_object ? item.province_object.name : item.province }}
            {{ item.postalCode }}, {{ item.country.title[currentLanguage] }}
          </div>
        </b-form-radio>
      </b-form-group>
    </div>
    <div class="text-center mt-2">
      <template v-if="!facilityMode">
        <div v-if="shippingCheck && shippingCheck !== 'tecnitex'">
          <span v-if="shipping !== ''"
            >{{ $t("orders.send_price") }}: {{ shipping }} €</span
          >
          <span v-else>{{ $t("orders.sent_outside_peninsula") }}</span>
        </div>
        <div v-if="shippingCheck && shippingCheck === 'tecnitex'">
          {{ $t("orders.send_price") }}: 0 €
        </div>
      </template>
  
      <div class="text-right mt-2">
        <span v-b-toggle.collapse-address @click="collapseInfo = !collapseInfo">
          {{ $t("orders.add_address") }}</span
        >
        <feather-icon
          :icon="collapseInfo ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="22"
        />
      </div>
      <b-collapse id="collapse-address">
        <hr />
        <validation-observer ref="addAddress">
          <b-card-title
            title-tag="h3"
            class="font-weight-bolder mb-1 text-center"
          >
            {{ $t("orders.new_address") }}
          </b-card-title>
          <b-form
            class="mt-2"
            @submit.prevent="handleSubmitAddAddress"
            @keydown.enter="handleSubmitAddAddress"
          >
            <div class="pl-3 pr-3 text-left">
              <!-- nombre address client -->
              <b-form-group
                :label="$t('Nombre')"
                label-for="register-name-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Nombre')"
                  rules="required"
                >
                  <b-form-input
                    id="register-name-client"
                    v-model="ClientName"
                    :state="errors.length > 0 ? false : null"
                    name="register-name-client"
                    :placeholder="$t('Nombre')"
                  />
                  <small ref="errorField" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
  
              <!-- dirección client -->
              <b-form-group
                :label="$t('Direccion')"
                label-for="register-address-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Direccion')"
                  rules="required"
                >
                  <b-form-input
                    id="register-address-client"
                    v-model="ClientAddress"
                    :state="errors.length > 0 ? false : null"
                    name="register-address-client"
                    :placeholder="$t('Direccion')"
                  />
                  <small ref="errorField" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- cp client -->
              <b-form-group
                :label="$t('CodigoPostal')"
                label-for="register-cp-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('CodigoPostal')"
                  rules="required"
                >
                  <b-form-input
                    id="register-cp-client"
                    v-model="ClientCP"
                    :state="errors.length > 0 ? false : null"
                    name="register-cp-client"
                    :placeholder="$t('CodigoPostal')"
                  />
                  <small ref="errorField" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
  
              <!-- town client -->
              <b-form-group
                :label="$t('Municipio')"
                label-for="register-town-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Municipio')"
                  rules="required"
                >
                  <b-form-input
                    id="register-town-client"
                    v-model="ClientTown"
                    :state="errors.length > 0 ? false : null"
                    name="register-town-client"
                    :placeholder="$t('Municipio')"
                  />
                  <small ref="errorField" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- telefono client -->
              <validation-provider #default="{ errors }" :name="$t('Teléfono')" rules="required">
                <b-form-group :label="$t('Telefono')" label-for="client-phone">
                  <b-form-input 
                    v-model="phone" 
                    name="reference" 
                    :placeholder="$t('Telefono')" 
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- province client -->
              <b-form-group
                :label="$t('Provincia')"
                label-for="register-province-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Provincia')"
                  rules="required"
                >
                  <v-select
                    v-if="ClientCountry && ClientCountry.code === 'ES'"
                    v-model="ClientProvince"
                    label="name"
                    :options="selectProvinces"
                    :filterable="true"
                    :searchable="true"
                  />
                  <b-form-input
                    v-else
                    v-model="ClientProvince"
                    :placeholder="$t('Provincia')"
                    :disabled="!ClientCountry"
                  />
                  <small ref="errorField" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- pais client -->
              <b-form-group
                :label="$t('Pais')"
                label-for="register-country-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Pais')"
                  rules="required"
                >
                  <v-select
                    id="register-country-client"
                    @input="handlerClientCountry"
                    :value="ClientCountry"
                    :state="errors.length > 0 ? false : null"
                    :options="listCountries"
                    label="name"
                    :clearable="false"
                    name="register-country-client"
                    :placeholder="$t('Pais')"
                    :disabled="facilityMode"
                  />
                  <small ref="errorField" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
  
              <b-row class="text-center mb-3">
                <b-col>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-1 mr-1 text-uppercase"
                  >
                    {{ $t("orders.add_address") }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-collapse>
      <hr />
      <template v-if="!facilityMode">
        <b-button
          class="mt-2"
          :disabled="shippingCheck === null"
          @click="addTransporte"
        >
          {{ $t("confirm") }}
        </b-button>
      </template>
      <template v-if="facilityMode">
        <b-button class="mr-2" @click="$emit('cancel')">
          {{ $t("Cancelar") }}
        </b-button>
        <b-button @click="addTransporte">
          {{ $t("orders.finish") }}
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from 'vue-select'

export default {
  name: "FormAddress",
  props: {
    facilityMode : {
      type: Boolean,
      default: false
    }
  },
  components: {
    vSelect,
  },
  data() {
    return {
      shippingCheck: null,
      ClientCountry: "",
      ClientAddress: "",
      ClientCP: "",
      ClientTown: "",
      ClientProvince: "",
      ClientCity: "",
      ClientName: "",
      collapseInfo: false,
      phone: '',
      phoneInstallation: ''
    };
  },
  watch: {
    shippingCheck() {
      if (!this.facilityMode && this.shippingCheck && this.shippingCheck !== 'tecnitex') {
        this.getPriceShipping({
          order: this.order.id,
          address: this.shippingCheck,
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      countries: "countries/getCountries",
      addresses: "addresses/getItems",
      shipping: "orders/getShipping",
      selectProvinces: "provinces/getSelectProvinces",
      currentLanguage: "languages/getCurrentLanguage",
      order: 'steps/getOrder',
    }),
    listCountries() {
      return this.countries.map((countrie) => {
        countrie.name = countrie.title[this.currentLanguage];
        return countrie;
      });
    },
    displayAdresses() {
      return this.facilityMode ? 
        this.addresses.filter((address) => address.country.code === 'ES') : 
        this.addresses
    }
  },
  methods: {
    ...mapActions({
      getPriceShipping: 'orders/getPriceShipping',
      saveTransport: "orders/saveTransport",
      saveFacilityTransport: "orders/saveFacilityTransport",
      getOrder: "steps/getOrder",
      getAddresses: 'addresses/getListAddress',
      addAddress: 'addresses/create',
    }),
    resetAddressForm() {
      this.ClientCountry = "";
      this.ClientAddress = "";
      this.ClientCP = "";
      this.ClientTown = "";
      this.ClientProvince = "";
      this.ClientCity = "";
      this.ClientName = "";
      this.shippingCheck = null;
      this.phone = ''
    },
    handlerClientCountry(value) {
      this.ClientCountry = value;
      this.ClientProvince = "";
    },
    createFormData() {
      const data = new FormData()

      data.append('name', this.ClientName)
      data.append('address', this.ClientAddress)
      data.append('town', this.ClientTown)
      data.append('country_id', this.ClientCountry.id)
      data.append('province', this.ClientCountry.code === 'ES' ? this.ClientProvince.id : this.ClientProvince)
      data.append('postalCode', this.ClientCP)
      data.append('type', 'shipping')
      data.append('client_id', this.order.client.id)
      data.append('phone', this.phone)

      return data
    },
    async addTransporte() {
      let shipping = "";
      if (this.shippingCheck !== "tecnitex") {
        shipping = this.shippingCheck;
      }
      if(this.facilityMode) {
        await this.saveFacilityTransport({
          order: this.order.id,
          address: shipping,
        });
      } else {
        await this.saveTransport({
          order: this.order.id,
          address: shipping,
        });
      }
      await this.getOrder();
      let addresSelected

      if(this.shippingCheck !== 'tecnitex') { 
        addresSelected = this.addresses.find((address) => address.id === this.shippingCheck)
      } else {
        addresSelected = this.shippingCheck
      }
      
      this.$emit('added-transportation', addresSelected)
    },
    async handleSubmitAddAddress() {
      await this.$refs.addAddress.validate().then(async (success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            // add address
            const responseCreateAddress = await this.addAddress({
              address: formData,
            });
            this.$root.$emit("bv::toggle::collapse", "collapse-address");
            this.resetAddressForm();
            // reload addres
            this.getAddresses({
              page: 1,
              per_page: 999,
              search: "",
              client: this.order.client.id,
              type: "shipping",
            });
            
            this.shippingCheck = responseCreateAddress.data.data.id
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      });
    },
  },
  mounted() {
    if(this.facilityMode) {
      this.ClientCountry = this.countries.find(({ code }) => code === 'ES')  
    }
    
  }
};
</script>
