var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"tarificador",staticClass:"container-fluid"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('span',{on:{"click":function($event){return _vm.setStep(_vm.step === 4.2 ? 4.1 : 1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")],1),_c('b-card-title',{staticClass:"font-weight-bolder mb-1",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('orders.step'))+" 1.2 ")]),_c('div',{staticStyle:{"width":"10%"}},[_c('hr',{staticClass:"linea"})]),_c('p',{staticClass:"mb-2 font-medium-3 fw-600"},[_vm._v(_vm._s(_vm.$t('orders.step_two_instructions')))])],1),_c('b-col',{attrs:{"cols":"12","sm":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_vm._l((_vm.valoresMedidas),function(item,index){return _c('div',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var medidasItems = ref.medidasItems;
return [_c('b-form-radio',{attrs:{"aria-describedby":medidasItems,"name":_vm.$t('orders.radius_measurements'),"value":item.value,"required":"required"},model:{value:(_vm.medidas),callback:function ($$v) {_vm.medidas=$$v},expression:"medidas"}},[_vm._v(_vm._s(item.name)+" ")])]}}],null,true)})],1)}),_c('validation-provider',{attrs:{"name":_vm.$t('orders.length'),"rules":_vm.longitudRequired},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('orders.length'),"label-for":"longitud"}},[_c('b-form-input',{attrs:{"name":_vm.$t('orders.length'),"placeholder":((_vm.$t('orders.length')) + " (mm)")},model:{value:(_vm.longitud),callback:function ($$v) {_vm.longitud=$$v},expression:"longitud"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('orders.height'),"rules":_vm.hRequired},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"label":_vm.$t('orders.height'),"label-for":"altura"}},[_c('b-form-input',{attrs:{"name":_vm.$t('orders.height'),"placeholder":((_vm.$t('orders.height')) + " (mm)")},model:{value:(_vm.altura),callback:function ($$v) {_vm.altura=$$v},expression:"altura"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])}),_c('validation-provider',{staticClass:"mt-1",attrs:{"name":_vm.$t('orders.units'),"rules":_vm.unitRequired},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('orders.units'),"label-for":"unidades"}},[_c('b-form-input',{attrs:{"name":_vm.$t('orders.units'),"placeholder":_vm.$t('orders.units'),"type":"number"},model:{value:(_vm.unidades),callback:function ($$v) {_vm.unidades=$$v},expression:"unidades"}})],1),_c('small',{staticClass:"text-danger mb-1"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])}),(_vm.object.ref=='MFBD E-120' || _vm.object.ref=='MSB DH-60/DA-150 Doble')?_c('div',{},[_c('validation-provider',{staticClass:"mt-1",attrs:{"name":_vm.$t('orders.drawer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('orders.drawer'),"label-for":"cajon"}},[_c('v-select',{attrs:{"label":"name","options":_vm.selectSerie,"filterable":false,"searchable":false},model:{value:(_vm.serieUpload),callback:function ($$v) {_vm.serieUpload=$$v},expression:"serieUpload"}})],1),_c('small',{staticClass:"text-danger mb-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1386098272)})],1):_vm._e(),_c('div',{staticClass:"pb-5 mb-5"},[_c('validation-provider',{staticClass:"mt-1",attrs:{"name":_vm.$t('orders.position'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('orders.position'),"label-for":"cajon"}},[_c('v-select',{attrs:{"label":"name","options":_vm.selectPositionMotor,"filterable":false,"searchable":false},model:{value:(_vm.positionMotor),callback:function ($$v) {_vm.positionMotor=$$v},expression:"positionMotor"}})],1),_c('small',{staticClass:"text-danger mb-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],2),_c('b-col',{attrs:{"sm":"6"}},[(_vm.object.ref == 'MSB DH-60/DA-150 Doble' || _vm.object.ref == 'MFBD E-120')?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getImg('medidas_doble.png'),"alt":""}}):_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getImg('medidas.png'),"alt":""}}),(_vm.object.ref=='MFBD E-120' || _vm.object.ref=='MSB DH-60/DA-150 Doble')?_c('b-row',{staticClass:"justify-content-center pb-3 pt-2",attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"text-center"},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('orders.series'))+" H")]),_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getImg('H-mini.jpg'),"alt":""}})]),_c('b-col',{staticClass:"text-center"},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('orders.series'))+" V")]),_c('img',{staticClass:"img-fluid text-center",attrs:{"src":_vm.getImg('V-mini.jpg'),"alt":""}})]),_c('b-col',{staticClass:"text-center"},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('orders.series'))+" VF")]),_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getImg('VF-mini.jpg'),"alt":""}})])],1):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"d-flex flex-column justify-content-between",attrs:{"cols":"12","sm":"4"}},[_c('Summary',{attrs:{"nextStep":_vm.next}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }