<template>
  <validation-observer
    ref="tarificador"
    class="container-fluid"
  >
    <b-row style="min-height: 70vh">
      <b-col cols="12">
        <b-row>
          <b-col sm="12">
            <span @click="setStep(7)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
              {{ $t('back') }}
            </span>
            <b-card-title
              title-tag="h1"
              class="font-weight-bolder mb-1"
            >
            {{ $t('orders.step') }} 3.1
            </b-card-title>
            <div style="width: 60px">
              <hr class="linea">
            </div>
            <p class="mb-2 font-medium-3 fw-600">
              {{ $t('orders.second_extras_instructions') }}
            </p>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="separation-right"
          >
            <b-row class="m-0">
              <b-col
                cols="12"
                sm="12"
                class="p-0 pb-2"
              >
                <v-select
                  v-model="category"
                  label="name"
                  :options="selectCategories"
                  :filterable="false"
                  :searchable="false"
                  :placeholder="$t('orders.select_category')"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    {{ option.name }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ option.name }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="category && category.ref === 'guias'"
                cols="12"
                sm="12"
                class="p-0"
              >
              <p class="mb-0 pt-1">
                  <feather-icon
                    size="16"
                    icon="AlertTriangleIcon"
                    class="mr-50 text-warning"
                  />
                  {{ $t('orders.add_guides') }}
                </p>
              </b-col>
              <b-col
                v-else-if="category"
                cols="12"
                class="p-0 m-0"
              >

                <div
                  v-for="(item) in products"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <b-form-group class="mb-2">
                    <b-row>
                      <b-col
                        cols="12"
                        sm="4"
                      >
                        <b-form-checkbox
                          v-model="extras"
                          class="checkbox"
                          :value="item"
                        >
                          <p class="mb-0 pt-1">
                            {{ item.name }}
                          </p>
                        </b-form-checkbox>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="8"
                      >
                        <b-form-textarea
                          id="textarea-small"
                          v-model="comments[item.id]"
                          size="sm"
                          :placeholder="$t('comments')"
                          class="mb-1"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-table
              hover
              responsive
              :items="linesExtras"
              :fields="columnsLinesExtras"
              class="table-summary"
              caption-top
            >
              <template #table-caption>
                {{ $t('orders.curtains_selected') }}
              </template>
              <template #cell(atributos)="data">
                <p class="mb-25">
                  {{ data.item.attr.l1 }}mm x {{ data.item.attr.h1 }}mm
                </p>
                <p class="mb-0">
                  {{ data.item.attr.motor }} <span v-if=" data.item.attr.guias">- {{ data.item.attr.guias }}</span>
                </p>
              </template>
            </b-table>
            <b-button
              variant="primary"
              class="mt-2 mr-1 text-uppercase"
              @click="nextStep()"
            >
              {{ $t('orders.add_extras') }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import {
  BRow, BCol, BCardTitle, BFormGroup, BFormCheckbox, BButton, BTable, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormCheckbox,
    BFormTextarea,
    BCol,
    BCardTitle,
    BFormGroup,
    BRow,
    BButton,
    vSelect,
    ValidationObserver,
    BTable,
  },
  data() {
    return {
      category: '',
      extras: [],
      comments: [],
      columnsLinesExtras: [
        {
          label: 'ID',
          key: 'type',
        },
        {
          label: this.$t('characteristics'),
          key: 'atributos',
        },
        {
          label: this.$t('units'),
          key: 'quantity',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      products: 'products/getItems',
      items: 'products/selectProducts',
      step: 'steps/getStep',
      linesExtras: 'steps/getLinesExtras',
      order: 'steps/getOrder',
      selectCategories: 'categories/getSelectCategories',
    }),
  },
  methods: {
    ...mapActions({
      listProducts: 'products/getListProducts',
      addLine: 'steps/addLine',
      getSelectCategories: 'categories/getSelectCategories',
    }),
    ...mapMutations({
      setStep: 'steps/setStep',
      setProducts: 'products/setItems',
      notifications: 'notifications',
      setShowNotifications: 'setShowNotifications',
    }),
    async nextStep() {
      this.$refs.tarificador.validate().then(async success => {
        if (success) {
          if (this.category.ref === 'guias') {
            const menor = []
            const mayor = []
            await this.products.forEach(p => {
              if (p.ref === 'SG-120') {
                menor.id = p.id
                menor.amount = p.price
                menor.tipe_price = p.tipe_price
                menor.count = 0
                menor.lines = []
              } else {
                mayor.id = p.id
                mayor.tipe_price = p.tipe_price
                mayor.amount = p.price
                mayor.count = 0
                mayor.lines = []
              }
            })
            let entramos = 0
            const promises = []
            await this.linesExtras.forEach(c => {
              if (c.product_object.type === 'cortina humo') {
                entramos = 1
                let amount = 0
                if (c.attr.h1 < 4500) {
                  if (menor.tipe_price === 'mml') {
                    amount = c.attr.l1 * menor.amount
                  } else if (menor.tipe_price === 'mmh') {
                    amount = c.attr.h2 * menor.amount
                  } else {
                    amount = menor.amount
                  }
                  menor.count = 1
                  menor.lines.push(c)
                  const attr = {}
                  attr.tipo = 'extras'
                  attr.comments = ''
                  attr.cortinas = menor.lines
                  menor.lines = []
                  promises.push(
                   {
                      amount,
                      quantity: menor.count,
                      product_id: menor.id,
                      order_id: this.order.id,
                      atributos: JSON.stringify(attr),
                    },
                  )
                } else {
                  if (mayor.tipe_price === 'mml') {
                    amount = c.attr.l1 * mayor.amount
                  } else if (mayor.tipe_price === 'mmh') {
                    amount = c.attr.h2 * mayor.amount
                  } else {
                    amount = mayor.amount
                  }
                  mayor.count = 1
                  mayor.lines.push(c)
                  const attr = {}
                  attr.tipo = 'extras'
                  attr.comments = ''
                  attr.cortinas = mayor.lines
                  mayor.lines = []
                  promises.push(
                    {
                      amount,
                      quantity: mayor.count,
                      product_id: mayor.id,
                      order_id: this.order.id,
                      atributos: JSON.stringify(attr),
                    }
                  )
                }
              }
            })
            for await (const promi of promises) {
              await this.addLine(promi)
            }
            if (!entramos) {
              this.notifications({ title: this.$t('orders.no_curtains_selected'), variant: 'danger' }, { root: true })
              this.setShowNotifications(true, { root: true })
            }
          } else {
            const lines = []
            let units = 0
            this.linesExtras.forEach(e => {
              lines.push(e)
              units++
            })
            this.extras.forEach(e => {
              let amount = 0
              if (e.tipe_price !== 'fijo') {
                this.linesExtras.forEach(c => {
                  if (e.tipe_price === 'mml') {
                    amount += c.attr.l1 * e.price
                  } else if (e.tipe_price === 'mmh') {
                    amount += c.attr.h2 * e.price
                  }
                })
                units = 1
              } else {
                amount = e.price
              }
              const attr = {}
              attr.tipo = 'extras'
              attr.comments = this.comments[e.id]
              attr.cortinas = lines
              this.addLine({
                amount,
                quantity: units,
                product_id: e.id,
                order_id: this.order.id,
                atributos: JSON.stringify(attr),
              })
            })
          }
        }
      })
    },
  },
  watch: {
    category() {
      this.listProducts({
        page: 1,
        per_page: 9999,
        category: this.category.id,
      })
    },
  },
  async created() {
    this.setProducts([])
    await this.getSelectCategories({ parent: 'extras' })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.checkbox label:after,
.checkbox label:before {
  padding-top: 15px;
  margin-top: 15px;
}
</style>
