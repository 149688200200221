<template>
  <b-card class="card-steps" >
    <b-container fluid >
      <b-row align-v="end">
        <First v-if="step === 1" />
        <Second v-if="step === 2" />
        <Third v-if="step === 3" />
        <SummaryOrder v-if="step === 4" />
        <First v-if="step === 4.1" />
        <Second v-if="step === 4.2" />
        <Third v-if="step === 4.3" />
        <FirstCuadro v-if="step === 5" />
        <SecondCuadro v-if="step === 6" />
        <FirstExtras v-if="step === 7" />
        <SecondExtras v-if="step === 8" />
        <Form v-if="step === 9" />
        <Form v-if="step === 4.9" />
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FirstExtras from '@/views/steps/FirstExtras'
import SecondExtras from '@/views/steps/SecondExtras'
import FirstCuadro from '@/views/steps/FirstCuadro'
import SecondCuadro from '@/views/steps/SecondCuadro'
import First from '@/views/steps/First'
import Second from '@/views/steps/Second'
import Third from '@/views/steps/Third'
import SummaryOrder from '@/views/steps/SummaryOrder'
import Form from '@/views/steps/Form'
import {
  BRow, BContainer, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    FirstExtras,
    SecondExtras,
    FirstCuadro,
    SecondCuadro,
    First,
    Second,
    Third,
    Form,
    BCard,
    BRow,
    ValidationObserver,
    BContainer,
    SummaryOrder,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      items: 'products/selectProducts',
      step: 'steps/getStep',
      order: 'steps/getOrder',
    }),
  },
  methods: {
    ...mapActions({
      calculator: 'products/calculator',
    }),
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
