<template>
  <validation-observer ref="tarificador" class="container-fluid" >
    <b-row>
      <b-col sm="12">
        <span @click="setStep(step === 4.2 ? 4.1 : 1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
          {{ $t('back') }}
        </span>
        <b-card-title
          title-tag="h1"
          class="font-weight-bolder mb-1"
        >
          {{ $t('orders.step') }} 1.2
        </b-card-title>
        <div style="width: 10%">
          <hr class="linea">
        </div>
        <p class="mb-2 font-medium-3 fw-600">{{ $t('orders.step_two_instructions') }}</p>
      </b-col>
      <b-col cols="12" sm="8" >
        <b-row>
          <b-col cols="12" sm="6">
            <div
                v-for="(item, index) in valoresMedidas"
                :id="item.id"
                :key="item.id"
                ref="row"
            >
              <b-form-group v-slot="{ medidasItems }">
                <b-form-radio
                    v-model="medidas"
                    :aria-describedby="medidasItems"
                    :name="$t('orders.radius_measurements')"
                    :value="item.value"
                    required="required"
                >{{ item.name }}
                </b-form-radio>
              </b-form-group>
            </div>

            <validation-provider
              #default="{ errors }"
              :name="$t('orders.length')"
              :rules="longitudRequired"
            >
              <b-form-group
                :label="$t('orders.length')"
                label-for="longitud"
              >
                <b-form-input
                  v-model="longitud"
                  :name="$t('orders.length')"
                  :placeholder="`${$t('orders.length')} (mm)`"
                />
              </b-form-group>
              <small ref="errorField" class="text-danger">{{ $t(errors[0]) }}</small>
            </validation-provider>
            <validation-provider
                #default="{ errors }"
                :name="$t('orders.height')"
                :rules="hRequired"
            >
              <b-form-group
                :label="$t('orders.height')"
                label-for="altura"
                class="mt-1"
              >
                <b-form-input
                  v-model="altura"
                  :name="$t('orders.height')"
                  :placeholder="`${$t('orders.height')} (mm)`"
                />
              </b-form-group>
              <small ref="errorField" class="text-danger">{{ $t(errors[0]) }}</small>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              :name="$t('orders.units')"
              :rules="unitRequired"
              class="mt-1"
            >
              <b-form-group
                :label="$t('orders.units')"
                label-for="unidades"
              >
                <b-form-input
                  v-model="unidades"
                  :name="$t('orders.units')"
                  :placeholder="$t('orders.units')"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger mb-1">{{ $t(errors[0]) }}</small>
            </validation-provider>
            <div
                v-if="object.ref=='MFBD E-120' || object.ref=='MSB DH-60/DA-150 Doble'"
                class=""
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('orders.drawer')"
                  rules="required"
                  class="mt-1"
              >
                <b-form-group
                    :label="$t('orders.drawer')"
                    label-for="cajon"
                >
                  <v-select
                    v-model="serieUpload"
                    label="name"
                    :options="selectSerie"
                    :filterable="false"
                    :searchable="false"
                  />
                </b-form-group>
                <small class="text-danger mb-1">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div
                class="pb-5 mb-5"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('orders.position')"
                  rules="required"
                  class="mt-1"
              >
                <b-form-group
                    :label="$t('orders.position')"
                    label-for="cajon"
                >
                  <v-select
                      v-model="positionMotor"
                      label="name"
                      :options="selectPositionMotor"
                      :filterable="false"
                      :searchable="false"
                  />
                </b-form-group>
                <small class="text-danger mb-1">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-col>
          <b-col sm="6">
            <img
                v-if="object.ref == 'MSB DH-60/DA-150 Doble' || object.ref == 'MFBD E-120'"
                :src="getImg('medidas_doble.png')"
                alt=""
                class="img-fluid"
            >
            <img
                v-else
                :src="getImg('medidas.png')"
                alt=""
                class="img-fluid"
            >
            <b-row  align-h="center" class="justify-content-center pb-3 pt-2" v-if="object.ref=='MFBD E-120' || object.ref=='MSB DH-60/DA-150 Doble'"
                   >
              <b-col class="text-center">
                <p class="text-center">{{ $t('orders.series') }} H</p>
                <img
                    :src="getImg('H-mini.jpg')"
                    alt=""
                    class="img-fluid"
                >
              </b-col>
              <b-col class="text-center">
                <p class="text-center">{{ $t('orders.series') }} V</p>
                <img
                    :src="getImg('V-mini.jpg')"
                    alt=""
                    class="img-fluid text-center"
                >
              </b-col>
              <b-col class="text-center">
                <p class="text-center">{{ $t('orders.series') }} VF</p>
                <img
                    :src="getImg('VF-mini.jpg')"
                    alt=""
                    class="img-fluid"
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="4" class="d-flex flex-column justify-content-between" >
        <Summary :nextStep="next" />
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Summary from '@/views/steps/Summary'
import {
  BRow, BContainer, BCol, BCardTitle, BFormGroup, BFormRadio, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BCardTitle,
    BFormGroup,
    BFormRadio,
    BButton,
    BRow,
    BCol,
    BFormInput,
    ValidationObserver,
    BContainer,
    vSelect,
    Summary,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      files: [],
      required,
      email,
      serieUpload: '',
      positionMotor: '',
      orderPrepare: false,
      valoresMedidas: [
        {
          name: 'L1/H1',
          value: 1,
        },
        {
          name: 'L2/H2',
          value: 2,
        },
      ],
      selectSerie: [
        {
          name: `${this.$t('orders.series')} H`,
          value: 'h',
        },
        {
          name: `${this.$t('orders.series')} V`,
          value: 'v',
        },
        {
          name: `${this.$t('orders.series')} VF`,
          value: 'vf',
        },
      ],
      selectPositionMotor: [
        {
          name: `${this.$t('orders.izquierda')}`,
          value: 'Izquierda',
        },
        {
          name: `${this.$t('orders.derecha')}`,
          value: 'Derecha',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      selectProducts: 'products/getSelectProductos',
      items: 'products/selectProducts',
      step: 'steps/getStep',
      object: 'steps/getObject',
    }),
    longitud: {
      get() {
        return this.$store.getters['steps/getLongitud']
      },
      set(newValue) {
        this.$store.commit('steps/setLongitud', newValue)
      },
    },
    medidas: {
      get() {
        return this.$store.getters['steps/getMedidas']
      },
      set(newValue) {
        this.$store.commit('steps/setMedidas', newValue)
      },
    },
    altura: {
      get() {
        return this.$store.getters['steps/getAltura']
      },
      set(newValue) {
        this.$store.commit('steps/setAltura', newValue)
      },
    },
    unidades: {
      get() {
        return this.$store.getters['steps/getUnidades']
      },
      set(newValue) {
        this.$store.commit('steps/setUnidades', newValue)
      },
    },
    longitudRequired() {
      if (this.object.ref === 'MFBD E-120' || this.object.ref === 'MSB DH-60/DA-150 Doble') {
        return 'required'
      }
      return 'required|minAndMaxMedidas12000'
    },
    hRequired() {
      if (this.object.ref === 'MSB DH-60/DA-150 Doble') {
        return 'required|minAndMaxMedidas10000'
      }
      return 'required|minAndMaxMedidas8000'
    },
    unitRequired() {
      return 'required|max20'
    }
  },
  methods: {
    ...mapActions({
      getSelectProducts: 'products/selectProducts',
      calculator: 'steps/calculator',
    }),
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    chargeData() {
      if (this.selectProducts.length > 0) {
        this.selectProducts.forEach(element => {
          if (element.category) {
            if (element.category.name === 'Cortinas cortafuegos') {
              this.cortafuegos.push(element)
            } else if (element.category.name === 'Cortinas de Humo') {
              this.humos.push(element)
            }
          }

        })
        this.stepOnePrepare = true
      }
    },
    next() {
      this.$refs.tarificador.validate().then(success => {
        if (success) {
          // todo: hacer llamada a la api
          const data = new FormData()
          data.append('product_id', this.object.id)
          if (this.medidas === 2) {
            data.append('L2', this.longitud)
            data.append('H2', this.altura)
            data.append('L1', 0)
            data.append('H1', 0)
          } else {
            data.append('L1', this.longitud)
            data.append('H1', this.altura)
            data.append('L2', 0)
            data.append('H2', 0)
          }
          data.append('serie', this.serieUpload.value)
          data.append('position', this.positionMotor.value)
          this.actionOrder(data)
        }
      })
    },
    async actionOrder(data) {
      await this.calculator({ calculator: data })
      //await this.setStep(3)
    },
    getImg(name) {
      return require(`@/assets/images/rater/${name}`)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vs__dropdown-menu{
  z-index: 1000000 !Important;
}
</style>
