<template>
  <validation-observer ref="tarificador" class="container-fluid">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col sm="12">
            <span @click="setStep(5)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
              {{ $t('back') }}
            </span>
            <b-card-title title-tag="h1" class="font-weight-bolder mb-1">
              {{ $t('orders.step') }} 2.1
            </b-card-title>
            <div style="width: 60px">
              <hr class="linea">
            </div>
            <p class="mb-2 font-medium-3 fw-600">
              {{ $t('orders.second_cuadro_instructions') }}
            </p>
          </b-col>
          <b-col cols="12" sm="6" class="separation-right">
            <b-row class="m-0">
              <b-col cols="12" class="pl-0 pr-4">
                <div class="banner-title mb-3" :style="imgCortafuegos">
                  <span>{{ $t('orders.control_panels') }}</span>
                </div>
              </b-col>
              <b-col>
                <div v-if="box.length !== 0">
                  <b-row>
                    <b-col cols="12" sm="6" class="separation-right p-0">
                      <p class="title-strong px-1 separation-bottom">{{ $t('orders.control_panels') }}</p>
                      <div v-for="(item, index) in box" :id="item.id" :key="item.id" ref="row">
                        <b-form-group v-slot="{ cuadros }">
                          <b-form-radio v-model="cuadro" :aria-describedby="cuadros" name="cortina-radios"
                            :value="index">
                            <p class="mb-0">
                              {{ index }}
                            </p>
                          </b-form-radio>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col cols="12" sm="6" class="p-0">
                      <b-row class="m-0">
                        <b-col cols="4" class="p-0">
                          <p class="title-strong px-1 separation-bottom">Panel</p>
                        </b-col>
                        <b-col cols="8" class="p-0">
                          <p class="title-strong px-1 separation-bottom">{{ $t('orders.signaling') }}</p>
                        </b-col>
                      </b-row>
                      <div class="m-0" v-for="(item, index) in box" v-if="cuadro === index" :id="item.id"
                        :key="item.id">
                        <b-row v-for="(modeloitem, modelo) in item" class="m-0" v-bind:key="modelo">
                          <b-col cols="4" class=" px-1 text-center">
                            <b-form-group v-slot="{ subcuadros }">
                              <b-form-radio 
                                v-model="modelocheck" 
                                :aria-describedby="subcuadros" 
                                name="modelo-radios"
                                :value="modeloitem" 
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="8" class="p-0">
                            <p class="m-0 px-1">{{ modelo }}</p>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-else class="text-danger">
                  <p>{{ $t('orders.curtains_excessive') }}</p>
                  <p>{{ $t('orders.reduce_curtains_message') }}</p>
                </div>
              </b-col>

            </b-row>

          </b-col>
          <b-col cols="12" sm="6">
            <b-table hover responsive :items="linesCuadro" :fields="columnsLinesCuadro" class="table-summary"
              caption-top>
              <template #table-caption>{{ $t('orders.curtains_selected') }}</template>
              <template #cell(atributos)="data">
                <p class="mb-25">
                  {{ data.item.attr.l1 }}mm x {{ data.item.attr.h1 }}mm
                </p>
                <p class="mb-0">
                  {{ data.item.attr.motor }} <span v-if=" data.item.attr.guias">- {{ data.item.attr.guias }}</span>
                </p>
              </template>
            </b-table>
            <b-button variant="primary" class="mt-2 mr-1 text-uppercase" @click="nextStep()">
              {{ $t('orders.add_control_panel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BRow, BCol, BCardTitle, BFormGroup, BFormRadio, BLink, BButton, BTable,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormRadio,
    BLink,
    BCol,
    BCardTitle,
    BFormGroup,
    BRow,
    BButton,
    ValidationObserver,
    BTable,
  },
  data() {
    return {
      modelocheck: '',
      columnsLinesCuadro: [
        {
          label: 'ID',
          key: 'type',
        },
        {
          label: this.$t('characteristics'),
          key: 'atributos',
        },
        {
          label: this.$t('units'),
          key: 'quantity',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      products: 'products/getItems',
      items: 'products/selectProducts',
      step: 'steps/getStep',
      linesCuadro: 'steps/getLinesCuadro',
      order: 'steps/getOrder',
      box: 'products/getControlBoxAdd',
    }),
    cuadro: {
      // getter
      get() {
        return this.$store.getters['steps/getCuadro']
      },
      // setter
      set(newValue) {
        this.$store.commit('steps/setCuadro', newValue)
      },
    },
    imgCortafuegos() {
      return 'background-image: url("' + require('@/assets/images/rater/cortafuegos.png') + '")'
    },
  },
  methods: {
    ...mapActions({
      getListControlBoxAdd: 'products/getListControlBoxAdd',
      addLine: 'steps/addLine',
    }),
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    nextStep() {
      this.$refs.tarificador.validate().then(success => {
        if (success) {
          const attr = {}
          attr.tipo = 'cuadro control'
          attr.modelo = this.modelocheck.modelo
          const lines = []
          this.linesCuadro.forEach(e => {
            lines.push(e)
          })
          attr.cortinas = lines
          this.addLine({
            amount: this.modelocheck.price,
            quantity: 1,
            product_id: this.modelocheck.id,
            order_id: this.order.id,
            atributos: JSON.stringify(attr),
          })
        }
      })
    },
  },
  watch: {
    cuadro() {
      this.modelocheck = ''
    },
  },
  async created() {
    const lines = []
    console.log(this.linesCuadro)
    this.linesCuadro.forEach(e => {
      lines.push(JSON.stringify({
        motor: e.attr.motor,
        num_motor: e.attr.num_motor * e.quantity,
      }))
    })
    await this.getListControlBoxAdd({
      lines,
    })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
