<template>
  <b-col cols="12">
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <div>
        <h2 class="font-weight-bolder m-0 hGoBack">
            <span @click="$router.go(-1)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
            </span>
          Estado del pedido
        </h2>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card-title
          title-tag="h1"
          class="font-weight-bolder mb-1"
        >
          {{ $t("orders.step") }} 4
        </b-card-title>
        <div style="width: 60px">
          <hr class="linea">
        </div>
        <p class="mb-2 font-medium-3 fw-600">
          ({{ $t("orders.order_summary") }} -
          {{ dateColumnYear(order.created_at) }}/T{{ order.id }})
        </p>
      </b-col>
      <Tables
        :lines-cortinas="linesCortinas"
        :lines-cuadros="linesCuadros"
        :lines-extras="linesExtras"
        :order="order"
        :actions-show="true"
        class="mb-2"
      />
      <!-- botones -->
      <b-col
        cols="6"
        class="pl-2"
      >
        <p>{{ $t("orders.can_add_products") }}:</p>
        <b-button
          class="mr-1"
          @click="setStep(4.1)"
        >
          {{ $t("orders.curtain") }}
        </b-button>
        <b-button
          class="mr-1"
          @click="checkCuadro()"
        >
          {{ $t("orders.control_panel") }}
        </b-button>
        <b-button
          class="mr-1"
          @click="checkExtras()"
        >
          {{ $t("orders.additional_feature") }}
        </b-button>
      </b-col>
      <b-col
        cols="6"
        class="text-right d-flex justify-content-end align-self-end"
      >
        <b-button
          class="mr-1"
          @click="checkCart()"
        >
          {{ $t("next") }}
        </b-button>
      </b-col>
    </b-row>
    <!-- modal añadir transporte -->
    <b-modal
      v-model="modalShowTransport"
      :hide-footer="true"
    >
      <p class="text-center mb-2">
        {{ $t("orders.select_delivery_address") }}
      </p>
      <FormAddress @added-transportation="addedTransportation" />
    </b-modal>
    <!-- modal finalizar-->
    <b-modal
      v-model="modalShowFinish"
      :hide-footer="true"
    >
      <p class="text-center mb-2">
        {{ $t("orders.modal_finish_title") }}
      </p>
      <div>
        <b-form-group>
          <b-form-radio
            v-model="finishCheck"
            name="finish-radios"
            value="no"
          >
            <strong>{{ $t("orders.no_facility") }}</strong>
          </b-form-radio>
          <b-form-radio
            v-model="finishCheck"
            name="finish-radios"
            value="yes"
          >
            <strong>{{ $t("orders.yes_facility") }}</strong>
          </b-form-radio>
        </b-form-group>
        <template v-if="finishCheck === 'yes'">
          <p class="text-center mb-2">
            {{ $t("facility_address") }}
          </p>
          <b-form-group>
            <b-form-radio
              v-model="instalationCheck"
              name="instalation-radios"
              value="SAME"
            >
              <strong>{{ $t("addresses_section.same_adrress_shipping") }}</strong>
            </b-form-radio>
            <b-form-radio
              v-model="instalationCheck"
              name="instalation-radios"
              value="SELECT"
            >
              <strong>{{ $t("addresses_section.select_address") }}</strong>
            </b-form-radio>
          </b-form-group>
        </template>
        <template v-if="instalationCheck === 'SELECT' && finishCheck === 'yes'">
          <div>
            <hr>
            <FormAddress
              :facility-mode="true"
              @added-transportation="[ finishType = 'facility', finishOrder()]"
              @cancel="modalShowFinish = false"
            />
          </div>
        </template>
      </div>

      <div
        v-if="instalationCheck !== 'SELECT' || finishCheck === 'no'"
        class="text-center mt-2"
      >
        <hr>
        <b-button
          class="mr-2"
          @click="modalShowFinish = false"
        >
          {{ $t("Cancelar") }}
        </b-button>
        <b-button
          :disabled="!finishCheck"
          @click="[finishType = finishCheck === 'no' ? null : 'facility', finishOrder()]"
        >
          {{ $t("orders.finish") }}
        </b-button>
      </div>
    </b-modal>
  </b-col>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
  BButton,
  BCol,
  BRow,
  BCardTitle,
  BFormGroup,
  BFormRadio,
  VBToggle,
} from 'bootstrap-vue'
import { TimestampToFlatPickrOnlyYear } from '@/libs/helpers'
import FormAddress from '@/views/steps/FormAddress.vue'
import Tables from '../orders/Tables'

export default {
  components: {
    BCol,
    BRow,
    BCardTitle,
    BButton,
    Tables,
    BFormGroup,
    BFormRadio,
    FormAddress,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      blockedFinalizar: true,
      shippingCheck: null,
      finishCheck: '',
      finishType: '',
      instalationCheck: 'SAME',
      modalShow: false,
      modalShowFinish: false,
      modalShowTransport: false,
      hayCortina: false,
      hayExtras: false,
      notifications: [],
    }
  },
  watch: {
    modalShowFinish(value) {
      if (value) {
        this.instalationCheck = 'SAME',
        this.shippingCheck = null,
        this.finishCheck = '',
        this.finishType = ''
      }
    },
  },
  computed: {
    ...mapGetters({
      order: 'steps/getOrder',
      checkControlBox: 'orders/getCheckControlBox',
      config: 'config/getConfig',
    }),
    listCountries() {
      return this.countries.map(countrie => {
        countrie.name = countrie.title[this.currentLanguage]
        return countrie
      })
    },
    linesCortinas() {
      const lines = []
      if (this.order) {
        this.order.lines.forEach(e => {
          const attr = JSON.parse(e.atributos)
          if (attr.tipo && attr.tipo === 'cortinas') {
            e.attr = attr
            lines.push(e)
          }
        })
      }

      if (lines.length > 0) {
        this.hayCortina = true
      } else {
        this.hayCortina = false
      }

      return lines
    },
    linesCuadros() {
      const lines = []
      if (this.order) {
        this.order.lines.forEach(e => {
          const attr = JSON.parse(e.atributos)
          if (attr.tipo && attr.tipo === 'cuadro control') {
            e.attr = attr
            lines.push(e)
          }
        })
      }

      return lines
    },
    linesExtras() {
      const lines = []
      if (this.order) {
        this.order.lines.forEach(e => {
          const attr = JSON.parse(e.atributos)
          if (attr.tipo && attr.tipo === 'extras') {
            e.attr = attr
            lines.push(e)
          }
        })
      }

      if (lines.length > 0) this.hayExtras = true

      return lines
    },
    existControlPanel() {
      return this.checkControlBox
    },
  },
  methods: {
    ...mapActions({
      getOrder: 'steps/getOrder',
      getCheckControlBox: 'orders/checkControlBox',
      getPriceShipping: 'orders/getPriceShipping',
      finish: 'orders/finish',
      addAddress: 'addresses/create',
      editAddress: 'addresses/edit',
      getSelectProvinces: 'provinces/selectProvinces',
      getAddresses: 'addresses/getListAddress',
      getListCountries: 'countries/getListCountries',
      getConfig: 'config/getConfig',
    }),
    dateColumnYear(time) {
      return TimestampToFlatPickrOnlyYear(time)
    },
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    checkCuadro() {
      if (!this.hayCortina) {
        this.$bvModal
          .msgBoxConfirm(this.$t('orders.no_curtains'), {
            bodyClass: 'bodyDelete',
            footerClass: 'footerDelete',
            okVariant: 'danger',
            okTitle: this.$t('accept'),
            cancelTitle: this.$t('Cancelar'),
            cancelVariant: 'outline-danger',
            centered: true,
          })
          .then(value => false)
      } else {
        return this.setStep(5)
      }
    },
    checkExtras() {
      // this.modalShow = false
      if (!this.hayCortina) {
        this.$bvModal
          .msgBoxConfirm(this.$t('orders.no_curtains'), {
            bodyClass: 'bodyDelete',
            footerClass: 'footerDelete',
            okVariant: 'danger',
            okTitle: this.$t('accept'),
            cancelTitle: this.$t('Cancelar'),
            cancelVariant: 'outline-danger',
            centered: true,
          })
          .then(value => false)
      } else if (this.existControlPanel === false) {
        this.$bvModal
          .msgBoxConfirm(this.$t('orders.no_control_panel'), {
            bodyClass: 'bodyDelete',
            footerClass: 'footerDelete',
            okVariant: 'danger',
            okTitle: this.$t('accept'),
            cancelTitle: this.$t('Cancelar'),
            cancelVariant: 'outline-danger',
            centered: true,
          })
          .then(value => {
            if (value === true) {
              return this.setStep(7)
            }
            return false
          })
      } else {
        return this.setStep(7)
      }
    },
    async openModalTransport() {
      await this.getAddresses({
        page: 1,
        per_page: 999,
        search: '',
        client: this.order.client.id,
        type: 'shipping',
      })
      await this.getSelectProvinces({
        page: 1,
        per_page: 9999,
        search: '',
        orderBy: '',
      })
      await this.getListCountries({
        page: 1,
        per_page: 9999,
        search: '',
        orderBy: '',
      })
      this.modalShowTransport = !this.modalShowTransport
    },
    async checkCart() {
      if (this.order.lines && this.order.lines.length > 0) {
        if (this.existControlPanel === false) {
          this.$bvModal
            .msgBoxConfirm(this.$t('orders.no_control_panel_confirm'), {
              bodyClass: 'bodyDelete',
              footerClass: 'footerDelete',
              okVariant: 'danger',
              okTitle: this.$t('accept'),
              cancelTitle: this.$t('Cancelar'),
              cancelVariant: 'outline-danger',
              centered: true,
            })
            .then(res => {
              if (res) {
                this.openModalTransport()
              }
            })
        } else {
          this.openModalTransport()
        }
      } else {
        const msg = this.$t('orders.no_finish_order')
        this.$bvModal.msgBoxConfirm(msg, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('accept'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
      }
    },
    addedTransportation(shipping) {
      if (shipping !== 'tecnitex') {
        this.shippingCheck = shipping.id
      } else {
        this.shippingCheck = shipping
      }

      if (shipping === 'tecnitex' || shipping.country.code === 'ES') {
        this.modalShowFinish = !this.modalShowFinish
      } else {
        this.finishType = 'shipping_outside'
        this.finishCheck = 'no'
        this.finishOrder()
      }

      this.modalShowTransport = false
    },
    async finishOrder() {
      this.modalShowFinish = false

      const maxImport = this.order.amount > parseFloat(this.config.pedido_mayor_que)

      if (this.finishType != null || maxImport) {
        let titles = null

        if (this.finishType != null) {
          titles = this.$t(`orders.finish_title_${this.finishType}`)
        }

        if (maxImport) {
          titles = titles
            ? `<span>${titles}</span><br/><span>${this.$t('orders.finish_title_max_import')}</span>`
            : `<span>${this.$t('orders.finish_title_max_import')}</span>`
        }

        const confirmation = await this.$bvModal
          .msgBoxConfirm(this.$t('orders.finish_message'), {
            titleHtml: titles,
            okTitle: this.$t('orders.finish'),
            cancelTitle: this.$t('Cancelar'),
            cancelVariant: 'outline-danger',
            centered: true,
          })

        if (!confirmation) {
          return
        }
      }

      let shipping = ''
      if (this.shippingCheck !== 'tecnitex') {
        shipping = this.shippingCheck
      }
      this.modalShowFinish = false
      this.finish({
        order: this.order.id,
        address: shipping,
        install: this.finishCheck,
      })
    },
  },
  async created() {
    await this.getCheckControlBox(this.order.id)
    await this.getOrder()
    await this.getConfig()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
import router from '@/router';
