<template>
  <validation-observer
    ref="tarificador"
    class="container-fluid"
  >
    <b-row>
      <b-col sm="12">
        <span @click="setStep(step === 4.9 ? 4.1 : 1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
          {{ $t('back') }}
        </span>
        <b-card-title
          title-tag="h1"
          class="font-weight-bolder mb-1"
        >
          {{ $t('Formulario') }}
        </b-card-title>
        <div style="width: 10%">
          <hr class="linea">
        </div>
        <p>{{ $t('orders.no_budgeted', { name:object.name }) }}</p>
        <p>{{ $t('orders.no_budgeted_instructions') }}</p>
        <p>{{ $t('contact_phone') }}: {{ config.phone }} </p>
      </b-col>
      <b-col
        cols="12"
        sm="8"
      >

        <validation-observer ref="sin_tarifa">
          <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
          >
            <b-row>
              <b-col cols="12">
                <validation-provider #default="{ errors }" :name="$t('affairs')" rules="required">
                <b-form-group
                  :label="$t('affairs')"
                  label-for="subject"
                >
                  <b-form-input
                    v-model="subject"
                    name="subject"
                    :placeholder="$t('affairs')"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="{ errors }" :name="$t('message')" rules="required">

                <b-form-group
                    :label="$t('message')"
                    label-for="message"
                >
                  <b-form-textarea
                      v-model="message"
                      :name="$t('message')"
                      :placeholder="'Mensaje'"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" class="mb-2">
                <b-form-group
                    :label="$t('files')"
                    label-for="product-image"
                >
                  <ImageDropzone ref="images" max-files="5" :files="files" />
                </b-form-group>
              </b-col>
              <b-col cols="12 text-right">
                <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2 mr-1 text-uppercase"
                >
                  {{ $t('Enviar') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BRow, BContainer, BCol, BCardTitle, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import FormService from "@/shared/service/form-service";

export default {
  components: {
    BCardTitle,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormInput,
    ValidationObserver,
    BContainer,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      files: [],
      required,
      email,
      orderPrepare: false,
      subject: '',
      message: '',
    }
  },
  computed: {
    ...mapGetters({
      step: 'steps/getStep',
      object: 'steps/getObject',
      config: 'config/getConfig',
    }),
  },
  methods: {
    ...mapActions({
      getConfig: 'config/getConfig',
      getSelectProducts: 'products/selectProducts',
      calculator: 'steps/calculator',
      send: 'steps/send',
    }),
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    async handleSubmit() {
      const valid = await this.$refs.sin_tarifa.validate()
      if (!valid) {
        this.$refs.errorField.scrollIntoView({
          behavior: 'smooth',
        block: 'end', 
        });
        return
      }
      this.send({ form: this.createFormData() })
    },
    createFormData() {
      const data = this.$refs.images.getFormData('images')

      FormService.appendEntry(data, this.subject, 'subject')
      FormService.appendEntry(data, this.message, 'message')
      FormService.appendEntry(data, this.object.id, 'product_id')

      return data
    },
  },
  mounted() {
    this.getConfig()
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
