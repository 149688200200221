<template>
  <validation-observer ref="tarificador" class="container-fluid" v-show="isVisible">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col sm="12">
            <span @click="setStep(4)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
              {{ $t('back') }}
            </span>
            <b-card-title title-tag="h1" class="font-weight-bolder mb-1">
              {{ $t('orders.step') }} 3
            </b-card-title>
            <div style="width: 60px">
              <hr class="linea">
            </div>
            <p class="mb-2 font-medium-3 fw-600">{{ $t('orders.first_extras_instructions') }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-table hover responsive :items="linesCortinas" :fields="columnsLines" class="table-summary" caption-top>
          <template #cell(check)="data">
            <b-form-checkbox v-model="checksLines" :value="data.item"></b-form-checkbox>
          </template>
          <template #cell(product_object)="data">
            <span v-if="data.item.product_object">{{ data.item.product_object.name[currentLanguage] }}</span>
          </template>
          <template #cell(atributos)="data">
            <p class="mb-25">
              {{ data.item.attr.l1 }}mm x {{ data.item.attr.h1 }}mm
            </p>
            <p class="mb-0">
              {{ data.item.attr.motor }} <span v-if=" data.item.attr.guias">- {{ data.item.attr.guias }}</span>
            </p>
          </template>
          <template #cell(amount)="data">
            <span>
              {{ priceFormatted(data.item.amount) }} €
            </span>
          </template>
          <template #table-caption>{{ $t('orders.control_panels') }}</template>
        </b-table>
      </b-col>
      <b-col cols="12" class="text-right">
        <b-button variant="primary" class="mt-2 mr-1 text-uppercase" @click="next()">
          {{ $t('next') }}
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BRow, BContainer, BCol, BCardTitle, BFormGroup, BFormCheckbox, BTable, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCol,
    BCardTitle,
    BRow,
    BTable,
    BFormCheckbox,
    BContainer,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isVisible: true,
      columnsLines: [
        {
          label: '',
          key: 'check',
        },
        {
          label: 'ID',
          key: 'type',
        },
        {
          label: this.$t('Descripcion'),
          key: 'product_object',
        },
        {
          label: this.$t('characteristics'),
          key: 'atributos',
        },
        {
          label: this.$t('units'),
          key: 'quantity',
        },
        {
          label: this.$t('orders.price'),
          key: 'amount',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      step: 'steps/getStep',
      order: 'steps/getOrder',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    linesCortinas() {
      const lines = []
      if (this.order) {
        this.order.lines.forEach(e => {
          const attr = JSON.parse(e.atributos)
          if (attr.tipo && attr.tipo === 'cortinas') {
            lines.push(e)
          }
        })
      }
      return lines
    },
    checksLines: {
      // getter
      get() {
        return this.$store.getters['steps/getLinesExtras']
      },
      // setter
      set(newValue) {
        this.$store.commit('steps/setLinesExtras', newValue)
      },
    },
  },
  methods: {
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    ...mapActions({
      listProducts: 'products/getListProducts',
    }),
    priceFormatted(price) {
      let val = (price / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    next() {
      this.$refs.tarificador.validate().then(success => {
        if (success) {
          this.setStep(8)
        }
      })
    },
  },
  created() {
    this.$store.commit('steps/setLinesExtras', [])
  },
}
</script>
